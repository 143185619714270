@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
html,
body {
  /* height: 100%; */
}

body {
  margin: 0;
  font-family: Inter;
  font-weight: 550;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(../public/img/grid.png);
  background-color: #081017;
}
#root {
  /* height: calc(100% - 172px); */
  user-select: none;
}
.no-scrollbar {
  max-height: calc(100vh - 367px);
  overflow-y: scroll;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For IE and Edge */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.task-scrollbar {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For IE and Edge */
}

.task-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

/* From Uiverse.io by ErzenXz */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
  cursor: pointer;
}

.toggle-switch input[type="checkbox"] {
  display: none;
}

.toggle-switch-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ddd;
  border-radius: 20px;
  transition: background-color 0.3s ease-in-out;
}

.toggle-switch-handle {
  position: absolute;
  top: 2.5px;
  left: 5px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.toggle-switch::before {
  content: "";
  position: absolute;
  top: -25px;
  right: -35px;
  font-size: 12px;
  font-weight: bold;
  color: #aaa;
  text-shadow: 1px 1px #fff;
  transition: color 0.3s ease-in-out;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch-handle {
  transform: translateX(35px);
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch-background {
  background-color: #288cee;
}

.toggle-switch input[type="checkbox"]:checked + .toggle-switch:before {
  content: "On";
  color: #288cee;
  right: -15px;
}

.toggle-switch
  input[type="checkbox"]:checked
  + .toggle-switch-background
  .toggle-switch-handle {
  transform: translateX(20px);
}

/* Basic editor styles */
.ProseMirror {
  margin-top: 4px;
  padding: 48px 8px 8px;

  border: 2px solid gray;
  border-radius: 4px;
  min-height: 200px;
  color: black;
}

.editor-mini .ProseMirror {
  padding-top: 8px;
}

.ProseMirror-focused {
  border-color: gray;
  outline: none;
}

.ProseMirror > * + * {
  margin-top: 16px;
}
