.loader {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #ffffff; /* Цвет верхней границы */
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite; /* Анимация кручения */
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg); /* Поворот на 360 градусов */
    }
  }
  