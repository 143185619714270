.businesses-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.business-item {
  box-sizing: border-box;
  background-color: #333a48cc;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  border-radius: 16px 16px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #232730;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
}

.modal.visible {
  transform: translateY(0);
  opacity: 1;
}

.modal.hidden {
  transform: translateY(100%);
  opacity: 0;
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 101;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px 60px 0 0;
}

.loader-modal {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.overlay {
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.3;
  margin-top: 100px;
}

.overlay-safe {
  margin-top: 100px;

  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
